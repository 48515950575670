
.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-header h1 {
  font-size: 3rem;
  margin: 30px 0;
}

.App-header p {
  font-size: 1.2rem;
  margin: 30px auto;
  max-width: 800px;
  text-align: center;
}

.Apps-container {
  padding: 40px;
}

.regular-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #282c34;
}

.Apps-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.App-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 330px;
  transition: transform 0.3s ease;
}

.App-card:hover {
  transform: translateY(-10px);
}

.accommodation-link {
  text-decoration: none;
  color: inherit;
}

.accommodation-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: auto;
}

.accommodation-info {
  padding: 20px;
}

.accommodation-info h3 {
  margin: 0;
  font-size: 1.3rem;
}

.accommodation-info p {
  font-size: 1rem;
  margin-top: 10px;
  color: #555;
}